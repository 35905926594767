export const variables = {
  elementCategories: [
    {id: 1, name:'Финансы'},
    {id: 2, name:'Производство'},
    {id: 3, name:'Качество'},
    {id: 4, name:'Охрана труда'},
    {id: 5, name:'Преемственность'},
    {id: 6, name:'Проекты'},
    {id: 7, name:'Другое'},
  ],
  scaleTypes: [
    {id: 1, name:'Повышающий', desc:'Количественный, повышающий - чем больше, тем лучше'},
    {id: 2, name:'Понижающий', desc:'Количественный, понижающий - чем меньше, тем лучше'},
    {id: 3, name:'Качественный', desc:'Качественный - не имеет возможности количественного измерения'},
  ],

  scaleTypesEnum:{
    TYPE_QUANTITY_UP:   1,
    TYPE_QUANTITY_DOWN: 2,
    TYPE_QUALITY:       3,
  }
}